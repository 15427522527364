<!-- https://progi.pro/dannie-i-metodi-komponentov-vue-ischezayut-na-odnom-elemente-pri-vizualizacii-s-pomoshyu-v-for-v-kachestve-kart-vuetify-6429981-->
<template>
  <win-block>
    <template #win-title-left>Игроки</template>
    <v-progress-linear
        :active="loading"
        color="primary"
        indeterminate
        absolute
        top
    ></v-progress-linear>
    <!--   <v-card flat class="pa-4">
         <v-toolbar flat>
           &lt;!&ndash;        <v-toolbar-title>My Document</v-toolbar-title>&ndash;&gt;
           &lt;!&ndash;        <v-spacer></v-spacer>&ndash;&gt;
           <div>
             <v-text-field
                 label="Имя игрока"
                 outlined
                 dense
                 filled
             ></v-text-field>
           </div>
         </v-toolbar>
       </v-card>
   -->

    <v-row justify="center" style="opacity: 0.5">
      <v-col cols="12">
        <v-checkbox
            v-model="search.sewa"
            label="Есть в SEWA"
            color="primary"
            hide-details
        ></v-checkbox>

      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            v-model="search.name"
            label="Имя игрока"
            outlined
            dense
            filled
            clearable
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-select
            v-model="karma_select"
            :items="params.karma_list"
            item-text="[1]"

            label="Карма"
            chips
            multiple
            filled
            outlined
            dense
            clearable
        >
        </v-select>
      </v-col>

      <v-col cols="12" sm="6">
        <v-select
            v-model="rank_select"
            :items="params.rank_list"
            item-text="[1]"
            item-value="[0]"
            label="Звание"
            chips
            multiple
            filled
            outlined
            dense
            clearable
            menu-props="auto"
        >
        </v-select>
      </v-col>


      <v-col cols="12" sm="6">
        <v-select
            disabled
            :items="chips"
            label="Ярлыки"
            filled
            chips
            outlined
            dense
            multiple
            clearable
        >

          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                x-small
                @click="()=>{}"
                @click:close="()=>{}"
            >
              <strong>{{ item }}</strong>&nbsp;
            </v-chip>
          </template>


        </v-select>
      </v-col>

    </v-row>

    <v-card flat min-height="300px">
      <v-row dense>
        <v-col
            v-for="item in items"
            :key="item['id']"
            cols="12" sm="6" lg="4"
        >

          <player :player="item" :params="params" @formKarma="showKarma($event)"/>

        </v-col>
      </v-row>


      <v-overlay
          absolute
          :opacity=0.8
          color="white"
          :value="loading"
      >
        <v-progress-circular
            indeterminate
            size="30"
            color="teal"
        ></v-progress-circular>
      </v-overlay>

    </v-card>


    <form-karma v-model="form_karma" :player="player_select" :params="params" @closeOK="closeKarma($event)"/>

  </win-block>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
    "player": () => import('./player.vue'),
    "form-karma": () => import('./form-karma.vue'),
  },
  data: () => ({
    search: {name: '', sewa: false, rank: '', karma: ''},
    form_karma: false,
    player_select: null,
    loading: false,
    //karma: ['хороший', 'плохой', 'никакой'],
    chips: ['военный', 'пират', 'инженер', 'мирный'],
    // rank: ['салага', 'пацак', 'чатланин', 'почетный чатланин', 'господин', ],
    rank_select: [],
    karma_select: [],
    items: [],
    params: {
      activityCredit: 0,
      rank_list: [],  // [[0,"салага"],[2,"пацак"],[4,"чатланин"]]
      karma_list: [], // [[-20000,"злой"],[0,"никакой"],[20000,"добрый"]]
      karma_interval: [-10000, 10000], //[-10000, 10000]
    }
  }),
  created() {
    this.initialize()
    let millisec = 2000
    if (!this.$store.state.develop) millisec = 100
    this.debounce = debounce(this.load_players, millisec)
  },
  watch: {
    karma_select(val) {
      // this.params.karma_list.reduce((a, e, i) => (window.console.log('=== a ', a,' e ',e,' i ',i)))
      // window.console.log('val indx', val.reduce((a, e, i) => (e in this.params.karma_list) ? a.concat(i) : a, []) )
      // this.params.karma_list.indexOf(10)
      // this.karma_select
      // this.params.karma_list.findIndex(item => item[0] === "Orange")
      // var xx = val.map(item => this.params.karma_list.findIndex(item));
      // window.console.log('this.karma_select ', this.karma_select.includes('никакой'))
      let xx = []
      this.params.karma_list.forEach((item, i) => {
        if (val.includes(item[1])) xx.push(i)
        // window.console.log('item ', item[1], ' i ', i)
      });
      this.search.karma = xx.join()
    },
    rank_select(val) {
      this.search.rank = val.join()
    },
    search: {
      deep: true,
      handler() {
        this.debounce()
      }
    }
  },
  methods: {
    showKarma(player) {
      this.player_select = player
      this.form_karma = true
    },
    closeKarma(player) {
      player
      // window.console.log('closeKarma', player)
      // let karma = this.$lib.mapping(player.karma.score, this.params.karma_interval)
      // window.console.log('karma', karma )
    },
    initialize() {
      this.$axios.get(this.$url.server_user_player)
          .then(response => {
            this.social_credit()
            this.params.activityCredit = response.data.activityCredit
          })
    },
    social_credit() {
      this.$axios.get(this.$url.server_user_social_credit)
          .then(response => {
            this.params.rank_list = response.data.ranks
            this.params.karma_list = response.data.karma
            this.params.karma_interval[0] = this.params.karma_list[0][0]
            this.params.karma_interval[1] = this.params.karma_list[this.params.karma_list.length - 1][0]
            this.load_players()
          })
    },
    load_players() {
      this.$axios.get(this.$url.server_user_players, {params: this.search, zLoader: [this, 'loading']})
          .then(response => {
            this.items = response.data
          })
    },
  }

}
</script>
