<template>
  <v-row no-gutters justify="center">
    <v-col cols="12" sm="10" md="9">
      <sparkline_players/>
    </v-col>
    <v-col cols="12" sm="10" md="9">
      <players/>
    </v-col>
  </v-row>
</template>

<script>
import sparkline_players from "@/site/modules/statistics/user-sparkline-players";
import players from "@/site/modules/server/social_credit/index";

export default {
  components: {sparkline_players, players},
  // components: {
  //   sparkline_players: () => import("@/site/modules/statistics/user-sparkline-players"),
  //   players: () => import("@/site/modules/server/social_credit/index"),
  // },
  data: () => ({
    // server_data: null,
  }),
}
</script>
